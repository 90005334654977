import React from "react";
import icon from "../../assets/images/website-development.png"
import icon2 from "../../assets/images/app-development.png"
import icon3 from "../../assets/images/softbare-devrlopment.png"
import icon4 from "../../assets/images/search-engine.png"
import icon5 from "../../assets/images/digital-market.png"
import icon6 from "../../assets/images/performance-marketing.png"
import img from "../../assets/images/service-icon-bg.png"
const Services = () => {
    return (
        <>
            <div className="bg-white py-24">
                <div className="container">
                    <h2 className="text-3xl md:text-5xl text-secondary font-bold text-center">Comprehensive Digital Solutions</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-6 md:mt-14">
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">Website Development</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">We create visually appealing, user-friendly websites tailored for schools,
                                colleges, and universities. Our expertise ensures your institution stands out online.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon2} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">App Development</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">Enhance your institution’s accessibility with custom mobile apps. We develop
                                apps that keep students and parents connected with your institution.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon3} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">Software Development</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">From administrative tools to learning management systems, we develop
                                software solutions that streamline operations and enhance the learning experience.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon4} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">Search Engine Optimization</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">Improve your website's visibility on search engines with our advanced SEO
                                strategies, ensuring more prospective students and parents find you online.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon5} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">Digital Marketing</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">Our digital marketing services help you reach a broader audience, engage
                                with your community, and drive more enrollments.</p>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-none">
                                <div className="relative">
                                    <img src={img} alt="img" />
                                    <img src={icon6} alt="img" className="inline-block absolute top-[12px] left-[10px]" />
                                </div>
                            </div>
                            <div className="flex-1 ml-8">
                                <h3 className="text-lg md:text-xl lg:text-2xl text-secondary font-bold">Performance Marketing</h3>
                                <p className="text-sm md:text-base lg:text-lg text-[#6C757D] font-normal mt-2 max-w-[420px]">Maximize your ROI with our performance marketing services. We create
                                targeted campaigns that deliver measurable results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Services;