import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import required modules
import { Autoplay } from 'swiper/modules';
import img from "../../assets/images/slider-img.png"
import img2 from "../../assets/images/about-board-img.png"
const OurPackage = () => {
    return (
        <>
            <div className="bg-white pb-14 md:pb-24">
                {/* <div className="container">
                    <div className="text-center">
                        <h2 className="text-3xl md:text-5xl text-secondary font-bold">Our Package</h2>
                        <p className="text-sm md:text-base text-[#6C757D] font-normal mt-5 max-w-[850px] m-auto">Edupine offers three comprehensive packages designed to meet the diverse needs of schools, colleges, and universities. Compare our packages below to find the one that best fits your institution’s requirements.</p>
                        <button className="text-lg text-[#F74780] font-normal mt-5">Learn More <i className="fas fa-chevron-right text-sm ml-1"></i></button>
                    </div>
                </div> */}
                <Swiper
                    slidesPerView={1.5} // Display half of the adjacent slides
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src={img} alt="img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img2} alt="img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img} alt="img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img2} alt="img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img} alt="img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={img2} alt="img" />
                    </SwiperSlide>
                </Swiper>

            </div>
        </>
    );
}
export default OurPackage;