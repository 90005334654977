import React from "react"
import img from "../../assets/images/digital-precence.png"
const DigitalPresence = () => {
    return (
        <>
            <div className="container">
                <div className="bg-[#5271FF] rounded-xl pb-10 drop-shadow-box">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="pl-8 lg:pl-20 pt-10 lg:pt-32">
                            <h2 className="text-3xl xl:text-5xl text-white font-bold xl:leading-tight">Ready to Transform<br />
                                Your Institution’s<br />
                                Digital Presence?</h2>
                            <p className="text-lg text-white font-normal mt-5 max-w-[519px]">Choose the package that best suits your needs and let
                                Edupine help you create a powerful online presence for
                                your institution. If you have any questions or need
                                assistance in choosing the right package, feel free to
                                contact us.</p>
                        </div>
                        <div className="text-end ml-auto">
                            <img src={img} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DigitalPresence;