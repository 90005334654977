import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo from "../assets/images/logo.svg";
import logo2 from "../assets/images/footer-logo.svg";
import logoDark from "../assets/images/logo-dark.svg"

const Header = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false); 

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleNavLinkClick = () => {
    setSidebarToggle(false);
  };

  const sidebarArray = [
    { name: "Home", link: "home" },
    { name: "About Us", link: "about" },
    { name: "Services", link: "services" },
    { name: "Packages", link: "packages" },
    { name: "Contact Us", link: "contact" },
  ];

  const sidebarArrayMobile = [
    { name: "Home", link: "/home" },
    { name: "About Us", link: "/home" },
    { name: "Services", link: "/home" },
    { name: "Privacy Policy", link: "/privacy-policy" },
    { name: "Packages", link: "/home" },
    { name: "Contact Us", link: "/home" },
];

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`sticky top-0 z-30 py-4 transition-all duration-300 ease-in-out ${isScrolled ? 'shadow-md bg-white' : 'shadow-none bg-transparent'}`}>
        <div className="container">
          <div className="flex">
          {isScrolled ? (
            <>
             <div className="flex-none max-w-full md:w-96">
             <Link to="/">
               <img src={logoDark} alt="logo" className="inline-block w-[163px]" />
             </Link>
           </div>
           <div className="flex-1">
             <nav className="hidden xl:block">
               <ul className="flex justify-end">
                 {sidebarArray.map((item, index) => (
                   <li key={index}>
                     <HashLink smooth to={`/#${item.link}`} className="flex py-2 text-base font-medium text-dark lg:ml-20 lg:inline-flex dark:text-dark-6 dark:hover:text-white">
                       {item.name}
                     </HashLink>
                   </li>
                 ))}
               </ul>
             </nav>
           </div>
           </>
          ) : (
            <>
             <div className="flex-none max-w-full md:w-96">
              <Link to="">
                <img src={logo} alt="logo" className="inline-block w-[163px]" />
              </Link>
            </div>
            <div className="flex-1">
              <nav className="hidden xl:block">
                <ul className="flex justify-end">
                  {sidebarArray.map((item, index) => (
                    <li key={index}>
                      <HashLink smooth to={`/#${item.link}`} className="flex py-2 text-base font-medium text-white lg:ml-20 lg:inline-flex dark:text-dark-6 dark:hover:text-white">
                        {item.name}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            </>
          )}
           <div className="block xl:hidden ml-4">
              <i
                className={`fas fa-bars ${isScrolled ? 'text-dark' : 'text-white'} text-2xl cursor-pointer`}
                onClick={handleSidebarToggle}
              ></i>
            </div>
            <aside
              className={`fixed right-0 top-0 z-30 overflow-y-auto h-full xl:hidden bg-[#eeebf6] block flex-col 
                transition-transform duration-300 ease-linear
                ${sidebarToggle ? "translate-x-0 w-[252px]" : "translate-x-[252px] w-[252px]"}`}
            >
              <div className="flex flex-col overflow-y-auto">
                <div className="flex items-center justify-between border-b border-primary p-3">
                  <Link to="">
                    <img className="w-[100px]" src={logo2} alt="Your Company" />
                  </Link>
                  <div className="size-6 bg-primary rounded-full text-white text-center cursor-pointer"
                    onClick={handleNavLinkClick}
                  >
                    <i className="fas fa-times mt-1"></i>
                  </div>
                </div>
                <nav className="py-[10px] px-[20px]">
                  <div>
                    <ul className="mb-6 flex flex-col gap-1.5">
                      {sidebarArrayMobile.map((item, index) => (
                        <li key={index} className="mb-[10px]">
                          <HashLink
                            smooth
                            to={`${item.link}`}
                            className={({ isActive }) =>
                              `flex items-center rounded-md w-[212px] h-[34px] p-[10px] justify-start ${isActive ? "bg-primary text-white" : "bg-transparent"
                              }`
                            }
                            onClick={handleNavLinkClick}
                          >
                            <div className="text-dark text-[16px] font-normal font-body ml-2">
                              {item.name}
                            </div>
                          </HashLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
