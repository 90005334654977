import React from "react";
import Header from "../Header";
import Banner from "../Home/Banner";
import WhyChoose from "../Home/WhyChoose";
import OurPackage from "../Home/OurPackage";
import AboutEdupine from "../Home/AboutEdupine";
import OurMission from "../Home/OurMission";
import Services from "../Home/Services";
import DigitalPresence from "../Home/DigitalPresence";
import Contact from "../Home/Contact";
import Footer from "../Footer";
import BasicPlan from "../Home/BasicPlan";
const Home = () => {
  return (
    <>
      <Header />
      <section id="home">
        <Banner />
      </section>
      <WhyChoose />
      <OurPackage />
      <section id="about">
        <AboutEdupine />
      </section>
      <OurMission />
      <section id="services">
        <Services />
      </section>
      <section id="packages">
        <BasicPlan />
      </section>
      <DigitalPresence />
      <section id="contact">
        <Contact />
      </section>
      <Footer />
    </>
  );
};
export default Home;
