import React from "react";
import img from "../../assets/images/our-mission.png"
const OurMission = () => {
    return (
        <>
            <div className="bg-[#EEEBF6] py-24">
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-10">
                        <div>
                            <h2 className="text-3xl md:text-5xl text-secondary font-bold">Our Mission</h2>
                            <p className="text-base md:text-lg text-[#6C757D] font-normal mt-5 max-w-[550px]">To empower educational institutions with cutting-edge digital solutions,
                            enhancing their ability to connect with students and parents.</p>
                        </div>
                        <div>
                            <img src={img} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default OurMission;