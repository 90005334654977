import React from "react";
import { Link } from "react-router-dom";
import banner from "../../assets/images/banner-bg.png"
import img from "../../assets/images/banner-img.png"
import shape from "../../assets/images/banner-shape.png"
const Banner = () => {

    return (
        <>
            <div className="bg-[#784ef0] pt-24 md:pt-40 -mt-[72px] bg-no-repeat bg-cover" style={{ backgroundImage: `url(${banner})` }}>
                <div className="relative">
                    <div className="container text-center">
                        <h1 className="text-white text-2xl md:text-3xl md:leading-tight lg:text-5xl lg:leading-tight xl:leading-tight font-bold block">Empowering Educational<br />
                            Institutions in the Digital Age</h1>
                        <p className="text-white text-lg md:text-xl lg:leading-10	font-normal block mt-4 lg:mt-8">From websites to apps digital marketing to performance marketing – Edupine does it all!</p>
                        <Link to="https://wa.me/9879372759" target="_blanck" className="inline-block bg-neutral text-white text-base md:text-lg font-medium rounded border-2 border-neutral py-3 px-7 mt-6 lg:mt-10 ">Get Started Now</Link>
                        <img src={img} alt="img" className="relative mt-7 lg:mt-16 -mb-[130px] md:-mb-[218px] lg:-mb-[230px] xl:-mb-[310px] 2xl:-mb-[380px]" />
                    </div>
                    <img src={shape} alt="shape" className="-mb-[2px]" />
                </div>
            </div>
        </>
    );
}
export default Banner;