import React from "react";
import img from "../../assets/images/choose-img.png"
const WhyChoose = () => {
    return (
        <>
            <div className="bg-white pt-24 pb-14">
                <div className="container">
                    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 md:gap-20">
                        <div className="bg-[#ffedf3] h-[87%] md:h-[80%] lg:h-[83%] xl:h-[87%] flex items-end">
                            <img src={img} alt="img"/>
                        </div>
                        <div>
                            <h2 className="text-3xl lg:text-4xl 2xl:text-5xl text-secondary font-bold">Why Choose Edupine?</h2>
                            <ul class="list-none mt-4 lg:mt-10">
                                <li className="flex mt-3 lg:mt-6">
                                    <div className="bg-[#F74780] rounded-full text-white size-6 text-center flex-none mr-5 mt-1">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-xl text-Amour font-bold ">Specialized in Education Industry</h3>
                                        
                                    </div>
                                </li>
                                <li className="flex mt-3 lg:mt-6">
                                    <div className="bg-[#F74780] rounded-full text-white size-6 text-center flex-none mr-5 mt-1">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-xl text-Amour font-bold ">Expertise in School, College, and University Digital Solutions</h3>
                                        
                                    </div>
                                </li>
                                <li className="flex mt-3 lg:mt-6">
                                    <div className="bg-[#F74780] rounded-full text-white size-6 text-center flex-none mr-5 mt-1">
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-xl text-Amour font-bold ">Website Delivered in 7 Working Days*</h3>
                                        
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default WhyChoose;