import React from "react";
import img from "../../assets/images/about-img.png"
import img2 from "../../assets/images/about-board-img.png"
import icon from "../../assets/images/Symbol.svg"
const AboutEdupine = () => {
    return (
        <>
            <div className="bg-[#41159c] relative pt-24">
                <img src={img} alt="img" className="absolute top-0" />
                <div className="container">
                    <div className="relative">
                    <h2 className="text-3xl md:text-5xl text-white font-bold text-center">About Edupine</h2>
                    <p className="text-sm md:text-base text-white font-normal mt-5 max-w-[950px] m-auto">Edupine is dedicated to revolutionizing the online presence of educational institutions. With a deep understanding of the unique needs of schools, colleges, and universities, we provide tailored solutions that drive engagement and success.</p>
                    <div className="lg:max-w-[75%] xl:max-w-[60%] 2xl:max-w-[45%] m-auto">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-8">
                            <div className="bg-[#6F33C8] rounded-full px-3 py-2 text-center">
                                <p className="text-white text-sm font-semibold"><img src={icon} alt="icon" className="inline-block mr-2 -mt-[2px]" />higly customizable</p>
                            </div>
                            <div className="bg-[#6F33C8] rounded-full px-3 py-2 text-center">
                                <p className="text-white text-sm font-semibold"><img src={icon} alt="icon" className="inline-block mr-2 -mt-[2px]" />Pixel perfect design</p>
                            </div>
                            <div className="bg-[#6F33C8] rounded-full px-3 py-2 text-center">
                                <p className="text-white text-sm font-semibold"><img src={icon} alt="icon" className="inline-block mr-2 -mt-[2px]" />Worldwide support</p>
                            </div>
                        </div>
                    </div>
                   
                    <div className="mt-14 text-center">
                        <img src={img2} alt={img} />
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}
export default AboutEdupine;