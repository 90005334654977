import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import banner from "../../assets/images/banner-bg.png";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <Header />
      <div
        className="bg-[#784ef0] py-24 md:py-40 -mt-[72px] bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="relative">
          <div className="container">
            <h1 className="text-white text-2xl md:text-3xl md:leading-tight lg:text-5xl lg:leading-tight xl:leading-tight font-bold block">
              Privacy Policy for EduPine
            </h1>
          </div>
        </div>
      </div>
      <div className="bg-white py-24">
        <div className="container">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-black text-base font-body font-normal mb-4">
                <b>Effective Date:</b> 1/11/2024
              </p>
              <p className="text-black text-base font-body font-normal">
                EduPine (“we,” “our,” or “us”) values your privacy and is
                committed to protecting your personal information. This Privacy
                Policy explains how we collect, use, and safeguard your data
                when you interact with our website, services, and marketing
                efforts.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">1. Information We Collect</p>
                <p className="text-black text-base font-body font-normal mb-4">
                We may collect the following types of personal information:
              </p>
              <ul className="mb-4 list-disc pl-[50px] flex flex-col gap-2">
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Personal Identifiable Information (PII):</b> Name, email address, phone number, school name, and role (e.g., Principal, Trustee).</p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Location Information: </b> City or state as part of form submissions.</p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Additional Information: </b> Any details you voluntarily share in forms or communication.</p>
                </li>
              </ul>
              <p className="text-black text-base font-body font-normal">
              We may also collect non-personal information like device type, browser type, and usage statistics through cookies and analytics tools.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">2. How We Use Your Information</p>
                <p className="text-black text-base font-body font-normal mb-4">
                We use the information you provide to:
              </p>
              <ul className="list-disc pl-[50px] flex flex-col gap-2">
                <li>
                    <p className="text-black text-base font-body font-normal">Contact you regarding your inquiry or service request.</p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Provide our services, including website creation, customization, and updates.
                    </p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Send updates, promotions, and information related to EduPine.
                    </p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Improve our services and website experience through analytics.

                    </p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Comply with legal obligations and resolve disputes.
                    </p>
                </li>
              </ul>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">3. Sharing Your Information</p>
                <p className="text-black text-base font-body font-normal mb-4">
                We will not sell, rent, or share your personal information with third parties except in the following cases:
              </p>
              <ul className="list-disc pl-[50px] flex flex-col gap-2">
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Service Providers:</b> We may share information with trusted partners who help us operate our business (e.g., hosting providers, payment processors).</p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Legal Requirements:</b> If required by law or to protect our rights and safety.
                    </p>
                </li>
              </ul>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">4. Cookies and Tracking Technologies</p>
                <p className="text-black text-base font-body font-normal mb-4">
                Our website uses cookies to enhance user experience. These may track your browsing activity for analytics or marketing purposes. By using our website, you agree to our use of cookies. You can disable cookies in your browser settings, but some functionality may be affected.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">5. Data Security</p>
                <p className="text-black text-base font-body font-normal mb-4">
                We implement industry-standard security measures to protect your data. However, no system is 100% secure, and we cannot guarantee absolute security of your information.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">6. Your Rights</p>
                <p className="text-black text-base font-body font-normal mb-4">
                You have the right to:
              </p>
              <ul className="mb-4 list-disc pl-[50px] flex flex-col gap-2">
                <li>
                    <p className="text-black text-base font-body font-normal">Access and review the personal information we hold about you.</p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Request correction or deletion of your information.
                    </p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal">Opt out of receiving marketing communications.
                    </p>
                </li>
              </ul>
              <p className="text-black text-base font-body font-normal mb-4">
              To exercise these rights, contact us at <Link to="mailto:hello@edupine.in">hello@edupine.in</Link>
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">7. Third-Party Links</p>
                <p className="text-black text-base font-body font-normal mb-4">
                Our website or services may include links to third-party websites. We are not responsible for the privacy practices of those websites. Please review their privacy policies before sharing any information.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">8. Updates to This Policy</p>
                <p className="text-black text-base font-body font-normal mb-4">
                We may update this Privacy Policy from time to time to reflect changes in our practices. The updated policy will be posted on this page with the effective date.
              </p>
            </div>
            <div className="border-b border-black"></div>
            <div>
                <p className="text-black text-lg font-body font-semibold mb-4">9. Contact Us</p>
                <p className="text-black text-base font-body font-normal mb-4">
                If you have any questions about this Privacy Policy or your data, please contact us:
              </p>
              <ul className="list-disc pl-[50px] flex flex-col gap-2">
                <li>
                    <p className="text-black text-base font-body font-normal mb-2"><b>Email:</b> <Link to="mailto:hello@edupine.in">hello@edupine.in</Link></p>
                </li>
                <li>
                    <p className="text-black text-base font-body font-normal"><b>Phone:</b> +91 9879372759
                    </p>
                </li>
              </ul>
            </div>
            <div className="border-b border-black"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
