import React, { useState } from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    // Validate fields
    if (!name && !email && !phone && !message) {
      alert("Please fill in at least one field.");
      return;
    }
    // Replace with your WhatsApp number (include country code)
    const phoneNumber = "9879372759";

    // Construct the message
    const text = `Name: ${name}  |  Email: ${email}  |  Phone: ${phone}  |  Message: ${message}`;

    // WhatsApp API URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${text}`;

    // Redirect to WhatsApp
    window.open(whatsappUrl, "_blank");
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <>
      <div className="container mt-24">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-5xl text-secondary font-bold">
            Get in Touch
          </h2>
          <p className="text-base text-[#6C757D] font-normal mt-5">
            We're here to help! Reach out to us with any questions or to get
            started on your project.
          </p>
        </div>
        <h3 className="text-lg font-bold text-secondary">Contact Details:</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
          <div className="bg-[#F2B51C] rounded-lg p-8">
            <div className="flex">
              <p className="text-base text-white font-bold">Phone:</p>
              <Link
                to="tel:+98793 72759"
                className="text-base text-white font-normal ml-1"
              >
                98793 72759
              </Link>
            </div>
          </div>
          <div className="bg-[#F74780] rounded-lg p-8">
            <div className="flex">
              <p className="text-base text-white font-bold">Email:</p>
              <Link
                to="mailto:hello@edupine.in"
                className="text-base text-white font-normal ml-1"
              >
                hello@edupine.in
              </Link>
            </div>
          </div>
          <div className="bg-[#5271FF] rounded-lg p-4">
            <div className="flex">
              <p className="text-base text-white font-bold">Address:</p>
              <p className="text-sm text-white font-normal ml-1">
                Ganesh Meridian, C-1005 A, Sarkhej - Gandhinagar Hwy, opp.
                Kargil Petrolpump, Sola, Ahmedabad, Gujarat 380060
              </p>
            </div>
          </div>
        </div>
        <h3 className="text-lg font-bold text-secondary mt-6">
          Send Us a Message
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          <div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-normal text-[#6C757D]">
                Name:
              </label>
              <input
                type="text"
                placeholder="Your Name*"
                className="w-full bg-[#F5F6F8] rounded-md py-[10px] px-5 text-[#999999] outline-none transition focus:border-primary active:border-primary"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-normal text-[#6C757D]">
                Email:
              </label>
              <input
                type="text"
                placeholder="Email*"
                className="w-full bg-[#F5F6F8] rounded-md py-[10px] px-5 text-[#999999] outline-none transition focus:border-primary active:border-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-normal text-[#6C757D]">
                Phone:
              </label>
              <input
                type="text"
                placeholder="Phone*"
                className="w-full bg-[#F5F6F8] rounded-md py-[10px] px-5 text-[#999999] outline-none transition focus:border-primary active:border-primary"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="mb-[10px] block text-base font-normal text-[#6C757D]">
                Message:
              </label>
              <textarea
                rows="5"
                placeholder=""
                className="w-full bg-[#F5F6F8] rounded-md py-[10px] px-5 text-[#999999] outline-none transition active:border-primary"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              className="bg-neutral text-white text-lg font-medium rounded border-2 border-neutral py-2 px-7 mt-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.60350723925!2d72.52467967617699!3d23.07499337913704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e834c4857596f%3A0xa0965704f9be238f!2sGanesh%20Meridian!5e0!3m2!1sen!2sin!4v1729751234562!5m2!1sen!2sin"
              width="100%"
              height="550"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
