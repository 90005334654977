import React from "react";

const BasicPlan = () => {
  return (
    <>
    <div className="bg-gradient-perfect pt-14 pb-24">
      <div className="container">
      <h2 className="text-3xl lg:text-5xl text-secondary font-bold text-center">Package Details</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-10">
          <div className="bg-[#f8f9ff] rounded-2xl border-t-2 border-neutral p-5 flex flex-col">
            <div className="flex-1">
              <h2 className="text-xl font-bold text-Amour">Seed Package</h2>
              <p className="text-sm text-dark font-normal mt-2">
                Our Seed Package is perfect for institutions looking to
                establish a basic online presence quickly and efficiently. Get a
                professional, informative website delivered in just 7 days.
              </p>
              <h3 className="text-3xl font-bold text-Amour mt-5">
                30k /<sub className="text-base">INR</sub>
              </h3>
              <div className="border border-neutral rounded-md mt-4 mb-4"></div>
              <div>
                <h3 className="text-lg font-bold text-dark">Features:</h3>
                <ul className="list-none mt-4 space-y-4">
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Informatic Website
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-none">
              <button className="bg-neutral text-white text-base rounded-md py-2 px-4 w-full mt-8 mb-2">
                Get Started
              </button>
            </div>
          </div>
          <div className="bg-[#f8f9ff] rounded-2xl border-t-2 border-neutral p-5 flex flex-col">
            <div className="flex-1">
              <h2 className="text-xl font-bold text-Amour">Stem Package</h2>
              <p className="text-sm text-dark font-normal mt-2">
                The Stem Package is ideal for institutions that need a more
                integrated solution. Along with a professional website, you get
                CRM integration and an admin edit panel to manage your site
                effortlessly.
              </p>
              <h3 className="text-3xl font-bold text-Amour mt-5">
                50k /<sub className="text-base">INR</sub>
              </h3>
              <div className="border border-neutral rounded-md mt-4 mb-4"></div>
              <div>
                <h3 className="text-lg font-bold text-dark">Features:</h3>
                <ul className="list-none mt-4 space-y-4">
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Informatic Website
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Integration of CRM
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Admin Edit Panel
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-none">
              <button className=" bg-neutral text-white text-base rounded-md py-2 px-4 w-full mt-8 mb-2">
                Get Started
              </button>
            </div>
          </div>
          <div className="bg-[#f8f9ff] rounded-2xl border-t-2 border-neutral p-5 flex flex-col">
            <div className="flex-1">
              <h2 className="text-xl font-bold text-Amour">Tree Package</h2>
              <p className="text-sm text-dark font-normal mt-2">
                The Tree Package offers a fully customized solution to meet your
                specific needs. Whether you require advanced features,
                e-commerce functionality, or top-notch SEO, we’ll create a
                tailored website that delivers exceptional results.
              </p>
              <h3 className="text-2xl font-bold text-Amour mt-5">
                Contact Us for Pricing
              </h3>
              <div className="border border-neutral rounded-md mt-4 mb-4"></div>
              <div>
                <h3 className="text-lg font-bold text-dark">Features:</h3>
                <ul className="list-none mt-4 space-y-4">
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Tailor-made website according to requirements
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        E-commerce functionality (if needed)
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-[#ededed] rounded-full text-neutral size-6 text-center flex-none mr-4">
                      <i className="fa-solid fa-check text-sm"></i>
                    </div>
                    <div className="flex-1">
                      <p className="text-base font-normal text-dark">
                        Advanced SEO
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-none">
              <button className=" bg-neutral text-white text-base rounded-md py-2 px-4 w-full mt-8 mb-2">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default BasicPlan;
